<template>
<app-modal-add-package
    :show="showModalPackage"
    :disabled="hasActiveBusiness"
    @close="showModalPackage = false"
    @refresh="refresh"
  />

  <!-- Modal Active Business-->
  <app-modal
    v-if="hasActiveBusiness"
    :show="showModalActive"
    @close="showModalActive = false"
    :textButtonCancel="$t('payment.info.active_later')"
  >
    <template v-slot:title>{{
      $t("payment.info.active_your_account_title")
    }}</template>
    <template v-slot:body>
      <p class="text-sm text-gray-500">
        {{ $t("subscription.active_your_account_description") }}
      </p>
    </template>
    <template v-slot:action>
      <app-button
        @onClick="$router.push({ name: 'business' })"
        @click="showModalActive = false"
        :showf70Icon="false"
        :primary="false"
        class="
          sm:ml-3 sm:w-auto
          bg-primary
          hover:bg-primary-100
          text-white
          outline-none
        "
      >
        {{ $t("payment.info.active_account") }}
      </app-button>
    </template>
  </app-modal>

  <div>
    <div class="px-4 border-b p-2 mt-5">
      <div
        class="
          px-5
          py-2
          flex
          m-auto
          lg:px-0
          container
          items-center
          justify-between
        "
      >
        <div>
          <p class="font-medium">{{ $t("subscription.package_list_title") }}</p>
        </div>
        <div class="flex flex-row space-x-2">
          <app-button
            width="w-1/8"
            :showf70Icon="false"
            @click="createPackage"
            :loading="loading"
          >
            <template v-slot:icon>
              <app-icon-outline
                name="PlusIcon"
                class="h-6 w-6 text-white mr-2"
              />
            </template>
            {{ $t("subscription.add_package") }}
          </app-button>
        </div>
      </div>
    </div>
  </div>
  <div>
    <app-table
      :loading="loading"
      @on-item-click="onItemClick"
      :apiResponse="apiPaginationResponse"
      @empty-button-clicked="createPackage"
      emptyIcon="app-icon-empty-subscription"
      :emptyTitle="$t('subscription.empty.title')"
      :emptyButton="$t('subscription.empty.button')"
      :emptyDescription="$t('subscription.empty.description')"
    >
      <template v-slot:header>
        <th class="font-bold">
          {{ $t("subscription.table_header.package_name") }}
        </th>
        <th class="font-bold">
          {{ $t("subscription.table_header.package_amount") }}
        </th>
        <th class="font-bold">
          {{ $t("subscription.table_header.package_frequency") }}
        </th>
        <th class="font-bold">{{ $t("general.actions") }}</th>
      </template>
      <template v-slot:body="slotData">
        <td>
          {{ slotData.model?.name }}
        </td>
        <td>
          {{
            $formats.currency(
              slotData.model?.currency,
              slotData.model?.amount ?? 0.0
            )
          }}
        </td>
        <td class="capitalize">
          {{
            slotData.model?.frequency_num == 1
              ? slotData.model?.frequency
              : slotData.model?.frequency +
                " (" +
                slotData.model?.frequency_num +
                ")"
          }}
        </td>
        <td>
          <div
            :class="[
              'flex space-x-2',
              isEditable(slotData.model) ? 'text-gray-400' : 'text-gray-200',
            ]"
          >
            <p @click.stop="onClickPackage(slotData.model)">
              {{ $t("general.edit") }}
            </p>
            <p @click.stop="openDeleteConfirmation(slotData.model)">
              {{ $t("general.delete") }}
            </p>
          </div>
        </td>
      </template>
    </app-table>
  </div>

  <app-modal
    :show="showDeleteConfirmation"
    @close="showDeleteConfirmation = false"
  >
    <template v-slot:title>{{ $t("subscription.delete_package") }}</template>
    <template v-slot:body>
      <p class="text-sm text-gray-500">
        {{ $t("subscription.delete_package_desc") }}
      </p>
    </template>
    <template v-slot:action>
      <app-button
        @onClick="deletePackage(selectedPackage)"
        :showf70Icon="false"
        :primary="false"
        type="button"
        class="
          sm:ml-3 sm:w-auto
          bg-error
          hover:bg-error
          text-white
          outline-none
        "
      >
        {{ $t("general.delete") }}
      </app-button>
    </template>
  </app-modal>
</template>

<script>
import Business_status from "@/utils/const/business_status";
export default {
  data() {
    return {
      showModalActive: false,
      showModalPackage: false,
      showDeleteConfirmation: false,
      selectedPackage: {},
      filters: "",
    };
  },
  computed: {
    apiPaginationResponse() {
      return this.$store.getters["subscriptionStore/apiPaginationResponse"];
    },

    business() {
      return this.$store.getters["businessStore/business"];
    },

    hasActiveBusiness() {
      if (this.business.business_status_id == null) {
        return false;
      }

      return this.business.business_status_id != Business_status.APPROVED;
    },

    loading() {
      return this.$store.getters["subscriptionStore/loading"];
    },
  },

  mounted() {
    this.fetchListPackage();
  },

  methods: {

    createPackage(){
      this.hasActiveBusiness ? this.onClickHasActiveBusiness() : this.onClickPackage()
    },

    async deletePackage(packages) {
      this.showDeleteConfirmation = false;

      const result = await this.$store.dispatch(
        "subscriptionStore/deletePackage",
        packages
      );
      if (!this.$lodash.isNil(result)) {
        this.refresh();
      }
    },

    openDeleteConfirmation(selectedPackage) {
      if (!this.isEditable(selectedPackage)) {
        return;
      }
      this.selectedPackage = selectedPackage;
      this.showDeleteConfirmation = true;
    },
    onClickHasActiveBusiness() {
      this.showModalActive = !this.showModalActive;
      this.showModalPackage = !this.showModalPackage;
    },

    onClickPackage(selectedPackage) {
      if (selectedPackage != null && !this.isEditable(selectedPackage)) {
        return;
      }
      this.showModalPackage = !this.showModalPackage;

      if (selectedPackage == null) {
        this.$store.commit("subscriptionStore/initPackage");
      } else {
        selectedPackage.frequency_selected = {
          id: selectedPackage.frequency,
          name: selectedPackage.frequency,
        };
        selectedPackage.start_after_selected = {
          id: selectedPackage.start_after_type,
          name: selectedPackage.start_after_type,
        };
        this.$store.commit("subscriptionStore/setPackage", selectedPackage);
      }
    },

    fetchListPackage() {
      this.$store.dispatch("subscriptionStore/retrievePackage", this.filters);
    },

    refresh() {
      this.fetchListPackage();
    },

    isEditable(selectedPackage) {
      return selectedPackage.subscribers?.length == 0;
    },

    onItemClick(selectedPackage) {
      this.$router.push({
        name: "subscription.subscriber.list",
        params: {
          package_id: selectedPackage._id,
          package_name: selectedPackage.name,
        },
      });
    },
  },
};
</script>